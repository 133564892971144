<template>
    <div class="card">
        <div class="mb-1 flex items-center">
            <p class="mr-auto text-lg text-black leading-none">{{ item.name }}</p>
            <template v-if="!item.is_predefined">
                <a-button 
                    class="ml-2"
                    size="small"
                    type="ui"
                    flaticon icon="fi-rr-edit" />
            </template>
        </div>
        <p class="mb-4">{{ item.description }}</p>
    </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
        }
    }
}
</script>

<style lang="scss" scoped>
.card {
    padding: 15px;
    padding-top: 20px;
    border-radius: 6px;
    background-color: #EFF2F5;
}
</style>