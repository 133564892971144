<template>
    <a-drawer class="drawer" :class="multiple && 'multiple_select'" :width="drawerWidth" destroyOnClose
              :afterVisibleChange="afterVisibleChange" :title="driwerTitle" :zIndex="zIndex" :visible="visible"
              @close="closeDrawer">
        <div class="drawer_body">
            <!-- :model="form"
            :rules="rules" -->
            <a-form-model>
                <div class="form-panel">
                    <p class="text-black mb-4">{{ $t('Description') }}</p>
                    <a-form-model-item :label="$t('Group name')">
                        <a-input :max-length="255" :placeholder="$t('Group name')" size="large" />
                    </a-form-model-item>
                    <a-form-model-item :label="$t('Description')">
                        <a-input :max-length="255" :placeholder="$t('Description')" size="large" />
                    </a-form-model-item>
                </div>

                <div class="form-panel">
                    <p class="text-black mb-4">{{ $t('Main modules') }}</p>

                    <div
                        class="module-row" 
                        v-for="module in mainModules" :key="module">
                        <p>
                            {{ module.title }}
                        </p>
                        <a-select size="large" :placeholder="$t('Access type')"></a-select>
                        <a-select size="large" :placeholder="$t('Add. conditions')"></a-select>
                    </div>

                </div>
                
                <div class="form-panel">
                    <p class="text-black mb-4">{{ $t('Additional modules') }}</p>

                    <div
                        class="module-row" 
                        v-for="module in addtionalModules" :key="module">
                        <p>
                            {{ module.title }}
                        </p>
                        <a-select size="large" :placeholder="$t('Access type')"></a-select>
                        <a-select size="large" :placeholder="$t('Add. conditions')"></a-select>
                    </div>
                </div>




            </a-form-model>

        </div>
        <div class="drawer_footer flex items-center">
            <!-- <template v-if="submitHandler">
                <a-button type="primary" block class="px-8" @click="submit">
                    {{ submitButtonText || $t("select") }}
                </a-button>
            </template>
<a-button type="ui" ghost block class="px-8" @click="visible = false">
    {{ $t("task.close") }}
</a-button> -->
        </div>
    </a-drawer>
</template>


<script>

export default {
    components: {
    },
    props: {
    },
    data() {
        return {
            mainModules: [
                { title: 'Проекты', },
                { title: 'Задачи', },
                { title: 'Структура', },
                { title: 'Команды', },
                { title: 'Календарь', },
                { title: 'Собрания', },
                { title: 'Чат', },
                { title: 'Мои файлы', },
                { title: 'Рабочий стол', },
                { title: 'Модерация', },
            ],
            addtionalModules: [
                { title: 'Консолидация', },
                { title: 'Обращения', },
                { title: 'Реестр спортивных объектов', },
                { title: 'Инвестиционные проекты', },
                { title: 'Отчеты', },
            ],

            visible: false,
        };
    },
    computed: {
        driwerTitle() {
            return this.title || this.$t("New access group");
        },
        drawerWidth() {
            const baseWidth = 720;
            const offset = 40;
            return this.windowWidth > baseWidth + offset
                ? baseWidth
                : this.windowWidth;
        },
        windowWidth() {
            return this.$store.state.windowWidth;
        },
        isMobile() {
            return this.$store.state.isMobile;
        },
    },
    methods: {
        afterVisibleChange(visible) {
            if (!visible) {
                this.zIndex = 1200;
                this.reset();
            }
        },
        closeDrawer() {
            this.visible = false;
        },
        open() {
            this.visible = true;
        },
    },
};
</script>

<style lang="scss" scoped>
.form-panel {
    padding: 20px;
    border: 1px solid #D9D9D9;
    border-radius: 8px;

    &+& {
        margin-top: 10px;
    }
}

.module-row {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 2fr 2fr;
    column-gap: 20px;
    &:not(:last-child) {
        margin-bottom: 15px;
    }
}



.drawer_body {
    display: flex;
    flex-direction: column;
    padding: 40px;
    // height: calc(100% - 40px);
}


.drawer::v-deep {

    .ant-drawer-body {
        padding: 0px;
        height: calc(100% - 40px);
    }

    .drawer_header {
        border-bottom: 1px solid var(--borderColor);
    }

    .drawer_footer {
        border-top: 1px solid var(--borderColor);
        height: 40px;
        background: var(--bgColor);
        padding: 0 15px;
        align-items: center;
    }

    .drawer_body {
        .drawer_scroll {
            height: 100%;
            overflow-y: auto;
            overflow-x: hidden;
        }
    }






    .ant-form-item {
        margin: 0;
        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }
}
</style>
